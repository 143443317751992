import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { ModalContext } from 'components/Modal'
import NavContext from 'components/NavProvider'
import StoryblokComponent from 'components/StoryblokComponent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/Button'
import RemarkRenderer from 'components/RemarkRenderer'

const Container = styled.div``

const sanitizeLang = lang =>
  ({
    'fr-ch': 'fr',
    default: 'en',
    'sv-sv': 'sv',
    'de-de': 'de',
    'de-at': 'de',
    'de-ch': 'de',
    no: 'no',
    fi: 'fi',
    da: 'da',
    'sl-si': 'sl'
  }[lang])

const sanitizeCountry = lang =>
  ({
    'fr-ch': 'Switzerland',
    'sv-sv': 'Sweden',
    'de-de': 'Germany',
    'de-at': 'Austria',
    'de-ch': 'Switzerland',
    no: 'Norway',
    fi: 'Finland',
    da: 'Denmark',
    'sl-si': 'Slovenia'
  }[lang])

export default function StoryblokForm ({ data }) {
  const { lang } = useContext(NavContext)
  const {
    dealer,
    formData,
    placesResult = null,
    onRequestClose,
    content
  } = useContext(ModalContext)
  const [dialog, setDialog] = useState(false)
  const handleClose = useCallback(() => {
    onRequestClose()
    setDialog(false)
  })
  return (
    <Container>
      <Formik
        initialValues={formData}
        onSubmit={(values, { setSubmitting }) => {
          dataLayer.push({
            type: content?.name,
            postCode: values?.postCode,
            dealer: dealer?.id,
            event: 'form-submit',
            brochureName: values?.brochureRequest,
            brochureType: values?.brochureDeliveryType,
            pageURL: window.location.href
          })

          fetch('/crm-functions/submitTicket', {
            method: 'POST',
            credentials: 'omit',
            body: JSON.stringify({
              type: data.type,
              autoAssign: data.autoAssign,
              assignSalesRep: data.assignSalesRep,
              ...(data.assignDealer && { assignStore: dealer?.id }),
              placesResult,
              language: sanitizeLang(lang),
              data: {
                country: sanitizeCountry(lang) || '',
                ...values,
                ...(!data.assignDealer
                  && dealer && {
                  internalComment: `Selected dealer: ${
                      dealer?.dealerNumber
                  } ${dealer?.name}`
                })
              }
            }),
            headers: {
              'content-type': 'application/json'
            }
          }).then(() => {
            console.log('submit')
            setDialog(true)
            setSubmitting(false)
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {data.body.map(inp => (
              <StoryblokComponent key={inp.id} component={inp} />
            ))}
          </form>
        )}
      </Formik>

      <Dialog onClose={handleClose} open={dialog}>
        <DialogTitle onClose={handleClose}>{data.successTitle}</DialogTitle>
        <DialogContent>
          <RemarkRenderer
            ast={data.successMessage?.childMarkdownRemark?.htmlAst}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{data.successButton}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
